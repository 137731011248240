// extracted by mini-css-extract-plugin
export var joinContainer = "our-story-module--joinContainer--TOdb7";
export var joinSection = "our-story-module--joinSection--9UhH1";
export var mission = "our-story-module--mission--f1Wn0";
export var missionContainer = "our-story-module--missionContainer--c5Xiy";
export var missionInner = "our-story-module--missionInner--2rhJD";
export var missionLogo = "our-story-module--missionLogo--OHgzC";
export var missionLogoContainer = "our-story-module--missionLogoContainer---dyTn";
export var missionTitle = "our-story-module--missionTitle--Jny5Q";
export var storyImage = "our-story-module--storyImage--iJTcU";
export var storyImageVertical = "our-story-module--storyImage--vertical--7Ku9T";
export var teamContainer = "our-story-module--teamContainer--dV6cd";
export var teamImage = "our-story-module--teamImage--DvkAX";
export var teamMember = "our-story-module--teamMember--dm4oQ";